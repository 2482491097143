
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router'
import _ from 'lodash'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import { type RootState } from '../../../store/store'
import ActionCard from '../../landlordModule/magic-actions/actionCard'

import { getNameUser } from '../../../functions/agent/agentModuleComponent/getNameUser'
import { getUnitAddress } from '../../../functions/agent/agentModuleComponent/getUnitAddres'
import { type User } from '../../../store/slices/userSlice'
import { type Unit } from '../../../store/slices/unitsSlice'
import { animationOptions, swalAttentionLeave, swalOptions } from '../../../functions/swalOptions'
import Swal from 'sweetalert2'
import UseUserType from '../../../hooks/UseUserType'
import { getCurrentDubaiDate } from '../../../functions/agent/date/getCurrentDateStrFormat'
import { updateStatusViewing } from '../../../store/actionsCreators/viewingActions/updateStatusViewing'
import { toUTCWithOffset } from '../../../functions/agent/date/toUTCWithOffset'
import { sendNotification } from '../../../functions/shared/notifications/from-agent/sendNotification'

const iconText = 'Success'
export const optionsSuccess = {
    ...swalOptions('success'),
    ...{
        title: 'Approved!',
        html: 'You have successfully confirmed viewing',
        showConfirmButton: true,
        iconHtml: '<div  id="lottie-icon-success></div>',
        confirmButtonText: 'Got it',
        didOpen: async () => {
            const lottieContainer = document.getElementsByClassName('swal2-icon-content')?.[0]
            const pathLottie = await import(`../../../../src/animations/${iconText}.json`)
            if (lottieContainer != null) {
                lottie.loadAnimation({
                    container: lottieContainer,
                    renderer: 'svg',
                    ...{ ...animationOptions, animationData: pathLottie.default }
                })
            }
        }
    }
}
export default function ViewingActionsForOtherAgents(): JSX.Element {
    const { unitID, objectID: viewingID } = useParams()

    const viewings = useAppSelector((state: RootState) => state.viewings.viewings)
    const viewing = _.find(viewings, (viewing) => viewing.id === Number(viewingID))
    const currentUser = useAppSelector((state: RootState) => state.user.currentUser)
    const units = useAppSelector((state: RootState) => state.units.units)
    const unit = _.find(units, (unit) => unit.id === Number(unitID))
    const viewingStatuses = viewing?.attributes?.Statuses
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const nameUser = getNameUser(currentUser as User)
    const unitAdress = getUnitAddress(unit as Unit)

    return (
        <>
            <div className={'d-flex flex-column w-100'}>
                {
                    (viewingStatuses?.includes('In-process') || viewingStatuses?.includes('Rescheduled by agent')) &&
                    <ActionCard
                        title={'Approve'}
                        description={'Approve viewing'}
                        onClick={async () => {
                            void swalAttentionLeave('Are you shure?').then(async (result: any) => {
                                if (result.isConfirmed) {
                                    const request = await dispatch(
                                        updateStatusViewing({
                                            viewID: viewingID,
                                            status: 'Approved',
                                            unitID,
                                            viewing,
                                            isOtherAgent: 'Agent'
                                        })
                                    )

                                    if (request.success && request.response.data) {
                                        await sendNotification(toUTCWithOffset(getCurrentDubaiDate(), 1), Number(viewing?.attributes?.AgentToAgentID), request?.response?.data?.id, 'user-viewing-approved', `Dear ,${nameUser} approved viewing on ${unitAdress}.`)

                                        const result = await Swal.fire(optionsSuccess).then(async (value: any) => {
                                            if (value.isConfirmed) {
                                                navigate(`/agent/modalView/viewing_modal/${unitID}/${viewingID}/agent_s_unit`)
                                            }
                                        })
                                    }
                                }
                            }).catch(console.log)
                        }
                        }
                        icon={'check_circle'}
                        styles={'mb-3 mx-n3'}
                    />
                }
                {
                    (viewingStatuses?.includes('Approved') || viewingStatuses?.includes('In-process') || viewingStatuses?.includes('Rescheduled by other agent') || viewingStatuses?.includes('Rescheduled by agent')) &&
                    <ActionCard
                        title={'Reject'}
                        description={'Reject viewing'}
                        onClick={async () => {
                            void swalAttentionLeave('Are you shure?').then(async (result: any) => {
                                if (result.isConfirmed) {
                                    const request = await dispatch(
                                        updateStatusViewing({
                                            viewID: viewingID,
                                            status: 'Rejected',
                                            unitID,
                                            viewing,
                                            isOtherAgent: 'Agent'
                                        })
                                    )

                                    if (request.success && request.response.data) {
                                        await sendNotification(toUTCWithOffset(getCurrentDubaiDate(), 1), Number(viewing?.attributes?.AgentToAgentID), request?.response?.data?.id, 'user-viewing-rejected', `Dear, ${nameUser} rejected viewing on ${unitAdress}.`)
                                        const result = await Swal.fire(optionsSuccess).then(async (value: any) => {
                                            if (value.isConfirmed) {
                                                navigate(`/agent/modalView/viewing_modal/${unitID}/${viewingID}/agent_s_unit`)
                                            }
                                        })
                                    }
                                }
                            }).catch(console.log)
                        }}
                        icon={'cancel'}
                        styles={'mb-3 mx-n3'}
                    />
                }

                {
                    (viewingStatuses?.includes('Approved') || viewingStatuses?.includes('In-process') || viewingStatuses?.includes('Rescheduled by agent')) &&
                    <ActionCard
                        title={'Reschedule'}
                        description={'Reschedule viewing'}
                        onClick={() => {
                            void swalAttentionLeave('Are you shure?').then(async (result: any) => {
                                if (result.isConfirmed) {
                                    navigate(`/agent/modal/reschedule_viewing_modal_from_agent/${unitID ?? '-'}/${viewingID ?? '-'}`)
                                }
                            }).catch(console.log)
                        }}
                        icon={'event_available'}
                        styles={'mb-3 mx-n3'}
                    />
                }

            </div>
        </>
    )
}
