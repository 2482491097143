import { type NavigateFunction } from 'react-router-dom'
import { reduceViewing, reduceViewingAfterCreate } from '../../../functions/agent/socket/reduceViewing'
import { reduceViewingAfterUpdateKey } from '../../../functions/agent/socket/reduceViewingAfterUpdateKey'
import { swalError, swalSuccess } from '../../../functions/swalOptions'
import { USER_EMAIL_KEY, USER_ID_KEY } from '../../../urls'
import { setAdvertises, setIsAlreadyOpenLink } from '../../slices/advertisesSlice'
import { type ISettings, setCurrentUserUnits, type User, userSlice, userUpdate } from '../../slices/userSlice'
import { addViewing, setViewings } from '../../slices/viewingsSlice'
import { store, type AppDispatch } from '../../store'
import { getChat } from '../chatActions'
import { getChatList } from '../chatListActions'
import { fetchSocketUser, fetchUser, fetchUserById, fetchUsers } from '../userActions'
import { setStatuses } from '../../slices/agentSlice'
import getAgentStatuses from '../../../functions/agent/documents/checkDocumentsForAproved'
import { documentAddUpdate } from '../../slices/documentSlice'
import { setNewViewingRescheduleSocket } from '../../slices/swalsSlice'
import { showFeedbackPopup } from '../../../functions/agent/viewings/showFeedbackPopup'

import { fetchOffers } from '../offerActions'
import awaitAction from '../../../functions/utils/awaitAction'
import { fetchSingleUnit, fetchUnits } from '../unitsActions'
import { removeDataAttributes } from '../../../functions/adapters/universal/removeDataAttributes'
import { unitsFetchingSuccess } from '../../slices/unitsSlice'
import customLog from '../../../functions/log'
import { reduceAdvertice } from '../../../functions/agent/socket/reduceAdvertice'
import { setIsAlreadyOpenLinkExtendAreas } from '../../slices/areasSlice'
import _ from 'lodash'
import { setIsUserVerified } from '../../slices/modalSlice'
import { is24HoursPassed } from '../../../functions/agent/date/is24HoursPassed'

import { processOfferSwal } from '../../../functions/agent/offers/showNegotiateSwals'
import { setHash } from '../../slices/hashSlice'
import { fetchViewings } from '../viewingsActions'
import { fetchAdvertises } from '../advertiseActions/fetchAdvertiseAction'
import { fetchDocuments } from '../documentActions'

export const socketReplayConnection = async (webSocket: WebSocket, dispatch: AppDispatch, navigate: NavigateFunction): Promise<void> => {
    const userID = window.localStorage.getItem(USER_ID_KEY)
    const messageObject = {
        action: 'get.state',
        deviceToken: store.getState().user.currentUser.DeviceToken,
        userID
    }
    webSocket.onopen = () => {
        console.log('Connected => get.state sended')
        webSocket.send(JSON.stringify(messageObject))
    }

    webSocket.onmessage = (event: MessageEvent) => {
        try {
            const message = event.data
            if (typeof message === 'string' && (message.startsWith('{') || message.startsWith('['))) {
                const data = JSON.parse(message)

                if (data?.action === 'open.app') {
                    if (data?.deviceToken === store.getState().user.currentUser.DeviceToken) {
                        webSocket.send(JSON.stringify(messageObject))
                    }
                }

                if (data.action === 'get.state') {
                    const { state, stateDev } = data
                    const currentState = store.getState().hash

                    if (process.env.REACT_APP_IS_DEV) {
                        if (stateDev?.user?.token === store.getState().user.currentUser.DeviceToken) {
                            if (stateDev?.users !== currentState.users) {
                                if ((stateDev?.user?.me !== stateDev.users)) {
                                    void dispatch(fetchUsers(true))
                                } else {
                                    void dispatch(fetchUser(true))
                                }
                            }

                            if (Boolean(stateDev.user.chatRooms) && stateDev.user.chatRooms !== currentState.chatRooms) {
                                void dispatch(getChatList())
                            }
                            if (stateDev.units !== currentState.units) {
                                void dispatch(fetchUnits(true))
                            }
                            if (stateDev.viewings !== currentState.viewings) {
                                void dispatch(fetchViewings(true))
                            }
                            if (stateDev.offers !== currentState.offers) {
                                void dispatch(fetchOffers(true, true))
                            }
                            if (stateDev.unitAdvertises !== currentState.unitAdvertises) {
                                void dispatch(fetchAdvertises(true))
                            }
                            if (stateDev.documents !== currentState.documents) {
                                void dispatch(fetchDocuments(true))
                            }
                            if (stateDev.unitKeys !== currentState.unitKeys) {
                                void dispatch(fetchUnits(true))
                            }

                            dispatch(setHash({ action: 'users', timestamp: stateDev.users }))
                            dispatch(setHash({ action: 'units', timestamp: stateDev.units }))
                            dispatch(setHash({ action: 'viewings', timestamp: stateDev.viewings }))
                            dispatch(setHash({ action: 'offers', timestamp: stateDev.offers }))
                            dispatch(setHash({ action: 'unitAdvertises', timestamp: stateDev.unitAdvertises }))
                            dispatch(setHash({ action: 'unitKeys', timestamp: stateDev.unitKeys }))
                            dispatch(setHash({ action: 'documents', timestamp: stateDev.documents }))
                            dispatch(setHash({ action: 'chatRooms', timestamp: stateDev.user.chatRooms }))
                        }
                    } else {
                        if (data.deviceToken === store.getState().user.currentUser.DeviceToken) {
                            if (state.users !== currentState.users) {
                                if ((state.user.me !== state.users)) {
                                    void dispatch(fetchUsers(true))
                                } else {
                                    void dispatch(fetchUser(true))
                                }
                            } if (
                                Boolean(state?.user?.chatRooms) && state.user.chatRooms !== currentState.chatRooms) {
                                void dispatch(getChatList())
                            }
                            if (state.units !== currentState.units) {
                                void dispatch(fetchUnits(true))
                            }
                            if (state.viewings !== currentState.viewings) {
                                void dispatch(fetchViewings(true))
                            }
                            if (state.offers !== currentState.offers) {
                                void dispatch(fetchOffers(true, true))
                            }
                            if (state.unitAdvertises !== currentState.unitAdvertises) {
                                void dispatch(fetchAdvertises(true))
                            }
                            if (state.documents !== currentState.documents) {
                                void dispatch(fetchDocuments(true))
                            }
                            if (state.unitKeys !== currentState.unitKeys) {
                                void dispatch(fetchUnits(true))
                            }

                            dispatch(setHash({ action: 'users', timestamp: state.users }))
                            dispatch(setHash({ action: 'units', timestamp: state.units }))
                            dispatch(setHash({ action: 'viewings', timestamp: state.viewings }))
                            dispatch(setHash({ action: 'offers', timestamp: state.offers }))
                            dispatch(setHash({ action: 'unitAdvertises', timestamp: state.unitAdvertises }))
                            dispatch(setHash({ action: 'unitKeys', timestamp: state.unitKeys }))
                            dispatch(setHash({ action: 'documents', timestamp: state.documents }))
                            dispatch(setHash({ action: 'chatRooms', timestamp: state.user.chatRooms }))
                        }
                    }
                }
                if ('action' in data && 'isDev' in data) {
                    const [collection, action] = data.action.split('.')
                    const entry = data?.entry

                    if (
                        (data?.isDev && process.env.REACT_APP_IS_DEV === 'true') ||
                    (!data?.isDev) && (!process.env.REACT_APP_IS_DEV)
                    ) {
                        if (collection === 'chat-room') {
                            if (action === 'update' && entry.id === store.getState().chat?.chat?.id) {
                                void dispatch(getChat(entry.id, 1, undefined, true))
                            }
                            if (action === 'update' && _.some(store.getState().chatList.chatList, { id: entry.id })) {
                                void dispatch(getChatList())
                            }
                            if (action === 'create') {
                                void dispatch(getChatList())
                            }
                        }
                        if (collection === 'unit-key') {
                            if (action === 'create') {
                                if (entry.Holder.id === Number(userID)) {
                                    const isThere = store.getState().viewings.viewings.filter((viewEl) => viewEl.id === entry?.Viewings?.[0]?.id) // ?.find((view) => view.attributes.User.data?.id === Number(userID))
                                    if (isThere != null && isThere.length > 0) {
                                        dispatch(setViewings(reduceViewing(entry?.Viewings?.[0], isThere[0])))
                                    }
                                }
                            } if (action === 'update') {
                                if (entry.Holder.id === Number(userID)) {
                                    const agentViewingsIDS = store.getState().viewings.viewings.filter((el) => el?.attributes?.User?.data?.id === Number(userID))?.map((el) => el.id)
                                    const isThere = store.getState().viewings.viewings.filter(
                                        view => view.id === entry?.Viewings?.filter((el: any) => agentViewingsIDS.includes(el.id))[0].id
                                    )
                                    //     const isThere = store.getState().viewings.viewings.filter((viewEl) => viewEl.id === entry?.Viewings?.[0]?.id) // ?.find((view) => view.attributes.User.data?.id === Number(userID))
                                    if (isThere != null && isThere.length > 0) {
                                        dispatch(setViewings(reduceViewingAfterUpdateKey(entry, isThere[0])))
                                    }
                                }
                            }
                        }

                        if (collection === 'user') {
                        //          console.log(data, '<=== data collection === user')
                            if (action === 'create') {
                            // dispatch(fetchSocketUser(entry?.id ?? -1))
                                dispatch(fetchUserById(entry?.id ?? -1))
                            } if (action === 'update') {
                            //        console.log(entry, '  entry ??')
                                if (entry.id === Number(userID)) {
                                    dispatch(fetchUser(true))
                                    const actualUser = store.getState().user?.currentUser
                                    const updateUser = { ...actualUser, ...entry } as User
                                    dispatch(userUpdate(updateUser))
                                    if (updateUser.emailVerified) {
                                        setIsUserVerified(true)
                                    } else {
                                        setIsUserVerified(false)
                                    }
                                    const docs = updateUser?.Documents
                                    dispatch(setStatuses(getAgentStatuses(docs)))
                                } else {
                                //  console.log(entry, '  entry ??')
                                    dispatch(fetchUserById(entry?.id ?? -1))
                                    // dispatch(fetchSocketUser(entry?.id ?? -1, true))
                                    if (entry.id === store.getState().chat?.chat?.User?.id) {
                                        if (store.getState().chat?.chat?.id != null) {
                                            void dispatch(getChat(Number(store.getState().chat.chat.id), 1, undefined, true))
                                        }
                                    }
                                }
                            } if (action === 'delete') {
                                dispatch(userSlice.actions.allUsersFetchingSuccess(
                                    store.getState().user.allUsers.filter(user => user.id !== entry.id)
                                ))
                            }
                        } if (collection === 'document') {
                            if (action === 'update') {
                                if (entry?.User?.id === Number(userID)) {
                                    dispatch(documentAddUpdate(entry)) // , isMy[0]
                                    const userDocs = store.getState().documents.documents.filter((doc) => doc.attributes.User.data?.id === Number(userID))
                                    dispatch(setStatuses(getAgentStatuses(userDocs)))
                                    // if (location.pathname === '/agent/modal/update_emirates_back/not/not') {
                                    //
                                    // } else {
                                    //     void swalSuccess(`Your ${getCorrectName(entry?.Type[0].__component)} is ${entry?.Statuses[0]}`).then(async (result: any) => {
                                    //         if (result.isConfirmed) {
                                    //             dispatch(documentAddUpdate(entry)) // , isMy[0]
                                    //             dispatch(setStatuses(getAgentStatuses(store.getState().documents.documents)))
                                    //         }
                                    //     })
                                    // }

                                // ATTENTION!!!!
                                } else {
                                    dispatch(fetchSocketUser(entry?.User?.id ?? -1, true))
                                    if (entry.id === store.getState().chat?.chat?.User?.id) {
                                        if (store.getState().chat?.chat?.id != null) {
                                            void dispatch(getChat(Number(store.getState().chat.chat.id), 1, undefined, true))
                                        }
                                    }
                                }
                            }
                        } if (collection === 'viewing') {
                            if (action === 'update') {
                                const isThere = store.getState().viewings.viewings.filter((viewEl) => {
                                    return viewEl.id === entry.id
                                })

                                if (isThere != null && isThere?.[0]?.attributes?.User.data?.id === Number(userID)) {
                                    dispatch(setViewings(reduceViewing(entry, isThere[0])))
                                    if (entry?.Statuses.includes('Rescheduled by BSO') && entry?.Notify.includes('Agent')) {
                                        dispatch(setNewViewingRescheduleSocket(true))
                                    }

                                    if (entry?.Statuses.includes('Waiting for feedback') && entry?.NotifyViewedDate === null) {
                                        const { oneCicleShowPopupsViewingReschedule, firstLengthSolidStackViewingsReschedule } = store.getState().swalsStates
                                        const agentViewings = store.getState().viewings.viewings.filter((el) => el?.attributes?.User?.data?.id === Number(userID))
                                        const viewingsCompleted = agentViewings.filter((view) => view?.attributes?.Statuses?.includes('Waiting for feedback') && (view?.attributes?.NotifyViewedDate === null || is24HoursPassed(view?.attributes?.NotifyViewedDate)))
                                        if (oneCicleShowPopupsViewingReschedule || (firstLengthSolidStackViewingsReschedule === 0 && !oneCicleShowPopupsViewingReschedule)) {
                                            if (viewingsCompleted.length > 0) {
                                                showFeedbackPopup(viewingsCompleted[0], dispatch, navigate)
                                            }
                                        }
                                    }
                                }
                            }
                            if (action === 'create') {
                                if (entry?.User.id !== Number(userID)) {
                                    dispatch(addViewing(reduceViewingAfterCreate(entry)))
                                    if (entry?.Statuses.includes('Rescheduled by BSO') && entry?.Notify.includes('Agent')) {
                                        dispatch(setNewViewingRescheduleSocket(true))
                                    }
                                }
                            }
                        } if (collection === 'offer') {
                            if (entry?.User?.id === Number(userID) || entry?.User?.id == null) {
                                dispatch(fetchOffers(true))
                                    .then(() => {
                                        if (action === 'update') {
                                        // if (entry.User.id === Number(userID)) {
                                            awaitAction(() => {
                                                const isMy = store.getState()?.offers?.offers?.filter((offEl) => offEl.id === entry.id)
                                                if (isMy != null && (isMy[0]?.attributes?.User?.data?.id === Number(userID))) {
                                                    const offer = { id: isMy[0]?.id, ...isMy[0]?.attributes }
                                                    void processOfferSwal(offer, dispatch, navigate)
                                                }
                                            },
                                            [
                                                {
                                                    path: 'swalsStates.oneCicleShowPopupsViewingReschedule',
                                                    checker: (val: boolean) => !val
                                                },
                                                {
                                                    path: 'swalsStates.showPopupViewingFeedback',
                                                    checker: (val: boolean) => !val
                                                },
                                                {
                                                    path: 'swalsStates.showPopupViewingReschedule',
                                                    checker: (val: boolean) => !val
                                                },
                                                {
                                                    path: 'swalsStates.newViewingRescheduleSocket',
                                                    checker: (val: boolean) => !val
                                                },
                                                {
                                                    path: 'swalsStates.newViewingFeedbackSocket',
                                                    checker: (val: boolean) => !val
                                                }
                                            ]
                                            )
                                        }
                                    })
                            }
                        }

                        if (collection === 'unit') {
                            if (action === 'update' && store.getState().units?.units?.some(unit => unit.id === entry.id)) {
                            // dispatch(fetchUnits(true))
                                dispatch(fetchSingleUnit(entry.id))
                                    .then((result: any) => {
                                        if (_.some((store.getState().user.currentUser as any).Units, { id: entry.id })) {
                                            const unitsCopy = _.cloneDeep((store.getState().user.currentUser as any).Units)
                                            const index = _.findIndex(unitsCopy, { id: entry.id })
                                            unitsCopy[index] = { id: entry.id, ...removeDataAttributes(result) }
                                            dispatch(setCurrentUserUnits(unitsCopy))
                                        }
                                        if (_.some(store.getState().chat.searchedInvites, (invite) => invite?.Unit?.attributes?.id === entry.id)) {
                                            const invitesCopy = _.cloneDeep(store.getState().chat.searchedInvites)
                                            const index = _.findIndex(invitesCopy, (invite) => invite?.Unit?.attributes?.id === entry.id)
                                            if (invitesCopy?.[index]?.Unit?.attributes != null) {
                                                (invitesCopy[index].Unit as any).attributes = result
                                            }
                                        }
                                        dispatch(unitsFetchingSuccess([...store.getState().units.units.filter((unit) => unit.id !== entry.id), { id: entry.id, attributes: result }]))
                                    })
                                    .catch((err: any) => {
                                        customLog(err)
                                    })
                                if (_.some((store.getState().user.currentUser as any).Units, { id: entry.id })) {
                                    dispatch(fetchSingleUnit(entry.id))
                                        .then((result: any) => {
                                            const unitsCopy = _.cloneDeep((store.getState().user.currentUser as any).Units)
                                            const index = _.findIndex(unitsCopy, { id: entry.id })
                                            unitsCopy[index] = { id: entry.id, ...removeDataAttributes(result) }
                                            dispatch(setCurrentUserUnits(unitsCopy))
                                        })
                                        .catch((err: any) => {
                                            customLog(err)
                                        })
                                }
                                if (_.some(store.getState().chat.searchedInvites, (invite) => invite?.Unit?.attributes?.id === entry.id)) {
                                    const invitesCopy = _.cloneDeep(store.getState().chat.searchedInvites)
                                    const index = _.findIndex(invitesCopy, (invite) => invite?.Unit?.attributes?.id === entry.id)
                                    dispatch(fetchSingleUnit(entry.id))
                                        .then((result: any) => {
                                            if (invitesCopy?.[index]?.Unit?.attributes != null) {
                                                (invitesCopy[index].Unit as any).attributes = result
                                            }
                                        })
                                        .catch((err: any) => {
                                            customLog(err)
                                        })
                                }
                            } else if (action === 'create') {
                                if (entry?.User?.id === store.getState().user.currentUser?.id) {
                                    dispatch(fetchSingleUnit(entry.id))
                                        .then((result: any) => {
                                            const unitsCopy = _.cloneDeep((store.getState().user.currentUser as any).Units)
                                            unitsCopy.push(result)
                                            dispatch(setCurrentUserUnits(unitsCopy))
                                        })
                                        .catch((err: any) => {
                                            customLog(err)
                                        })
                                }
                            }
                        } if (collection === 'unit-advertise') {
                            if (action === 'update') {
                                const isThere = store.getState().advertises.advertises.filter((viewAdv) => {
                                    return viewAdv.id === entry.id
                                })
                                if (isThere != null && isThere?.[0]?.attributes?.User.data?.id === Number(userID)) {
                                    dispatch(setAdvertises(reduceAdvertice(entry, isThere[0])))
                                    if (
                                        isThere?.[0]?.attributes?.Statuses?.includes('Docs received') &&
                                    ((isThere?.[0]?.attributes?.Links) === null || isThere?.[0]?.attributes?.Links === undefined)
                                    ) {
                                    // if (!location.pathname.includes('/agent/modalView/advertise_modal/') || !location.pathname.includes('/agent_advertise_create_start_date/')) {
                                    //     showAdvertisesSwals(`${isThere?.[0].id},`, navigate, 'Warning')
                                    //     dispatch(setAlreadyShown(true))
                                    // }
                                    }
                                }
                            }
                        }

                        if (collection === 'order') {
                            if (action === 'paid') {
                            //       console.log(data, 'data')
                                const status = data?.link.split('status=')[1]
                                if (data?.link.includes('agent_settings_for_subscriptions') && status === 'true') { //  && status === 'true'
                                    const firstSlice = data?.link.split('/agent_settings_for_subscriptions/')
                                    const userEmail = firstSlice?.[1].split('/')[0]
                                    const userEMAIL = window.localStorage.getItem(USER_EMAIL_KEY)
                                    const userID = window.localStorage.getItem(USER_ID_KEY)
                                    const currentUser = store.getState().user.currentUser
                                    const userSett = currentUser?.Settings as ISettings[]
                                    if (userEMAIL === userEmail) {
                                        if (!location.pathname.includes('/agent/modal/agent_settings_for_subscriptions/')) {
                                            if (!(
                                                currentUser &&
                                            userSett &&
                                            userSett.length > 0 &&
                                            userSett[0].Areas &&
                                            userSett[0].Areas.length > 1
                                            )) {
                                                if (data?.link.includes('Standard')) {
                                                    navigate(`/agent/modal/agent_settings_for_subscriptions/${userID as string}/${'Standard'}`)
                                                } if (data?.link.includes('Premium')) {
                                                    navigate(`/agent/modal/agent_settings_for_subscriptions/${userID as string}/${'Premium'}`)
                                                }
                                            }
                                        }
                                    }
                                } else if (data?.link.includes('/agent/modal/agent_advertise_create_duration/')) {
                                    if (status === 'true') {
                                        const userID = window.localStorage.getItem(USER_ID_KEY)
                                        const firstSlice = data?.link.split('/agent_advertise_create_duration/')
                                        const unitId = firstSlice[1].split('/')[0]
                                        const userId = firstSlice[1].split('/')[1]?.split('?')[0]
                                        const isAlreadyOpenLink = store.getState().advertises.isAlreadyOpenLink
                                        //     console.log({ userID, firstSlice, unitId, userId })
                                        if (userID === userId) {
                                            if (!isAlreadyOpenLink) {
                                                dispatch(setIsAlreadyOpenLink(true))
                                                //  navigate(`/agent/modal/agent_advertise_create_duration/${unitId}/${userId}`)
                                                void swalSuccess('You are successfully purchased Bricks').then(async (result: any) => {
                                                    if (result.isConfirmed) {
                                                        navigate(`/agent/modal/agent_advertise_create_duration/${unitId}/${userId}`)
                                                    }
                                                })
                                            } else {
                                                if (data?.try === 9) {
                                                    dispatch(setIsAlreadyOpenLink(false))
                                                }
                                            }
                                        }
                                    } else {
                                        void swalError('Sorry, your payment for Bricks was declined').then(async (result: any) => {
                                            if (result.isConfirmed) {
                                            // navigate(`/agent/modal/agent_advertise_create_duration/${unitId}/${userId}`)
                                            }
                                        })
                                    }
                                } else if (data?.link.includes('/agent/modal/agent_settings_for_areas_extension/')) {
                                    if (data.paid) { // status === 'true'
                                        const userID = window.localStorage.getItem(USER_ID_KEY)
                                        const firstSlice = data?.link.split('/agent/modal/agent_settings_for_areas_extension/')
                                        const unitId = firstSlice[1].split('/')[0]
                                        const userId = firstSlice[1].split('/')[1].split('?')[0]
                                        const isAlreadyOpenLinkExtendAreas = store.getState().areas.isAlreadyOpenLinkExtendAreas
                                        if (userID === userId) {
                                            if (!isAlreadyOpenLinkExtendAreas) {
                                                dispatch(setIsAlreadyOpenLinkExtendAreas(true))
                                                //  navigate(`/agent/modal/agent_settings_for_areas_extension/${unitId}/${userId}`)
                                                void swalSuccess('You are successfully purchased Areas Extension').then(async (result: any) => {
                                                    if (result.isConfirmed) {
                                                        navigate(`/agent/modal/agent_settings_for_areas_extension/${unitId}/${userId}`)
                                                    }
                                                })
                                            } else {
                                                if (data?.try === 9) {
                                                    dispatch(setIsAlreadyOpenLinkExtendAreas(false))
                                                }
                                            }
                                        }
                                    } else {
                                        void swalError('Sorry, your payment for Areas Extension was declined').then(async (result: any) => {
                                            if (result.isConfirmed) {
                                            // navigate(`/agent/modal/agent_advertise_create_duration/${unitId}/${userId}`)
                                            }
                                        })
                                    }
                                } else {
                                    const userID = window.localStorage.getItem(USER_ID_KEY)
                                    if (data.user === Number(userID)) {
                                        const isAlreadyOpenLink = store.getState().advertises.isAlreadyOpenLink
                                        if (!isAlreadyOpenLink) {
                                            dispatch(setIsAlreadyOpenLink(true))
                                            if (data.paid) {
                                                void swalSuccess('You are successfully purchased Bricks').then(async (result: any) => {
                                                    if (result.isConfirmed) {
                                                        navigate('/agent/profile/me')
                                                    }
                                                })
                                            } else {
                                                void swalSuccess('Sorry, your payment for Bricks was declined').then(async (result: any) => {
                                                    if (result.isConfirmed) {
                                                        navigate('/agent/profile/me')
                                                    }
                                                })
                                            }
                                        } else {
                                            if (data?.try === 9) {
                                                dispatch(setIsAlreadyOpenLink(false))
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } catch (err) {
            // console.log('Failed socket message:', event.data)
            console.log(err)
        }
    }
}
