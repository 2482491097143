import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { type RootState } from '../../store/store'

import Icon from '../../app/icon/Icon'
import { setIsPreviousPathChooseAreas, setShowPopupScheduleFilters } from '../../store/slices/modalSlice'

import { setMyArchivedUnitsOpen } from '../../store/slices/unitsSlice'

interface IProps { active: string }

export default function AgentHeader({ active }: IProps): JSX.Element {
    const navigate = useNavigate()

    const isShown = useAppSelector((state: RootState) => state.voice.isShown)
    const dispatch = useAppDispatch()
    const userDevice = useAppSelector((state) => state.app.userDevice)
    const isPreviousPathChooseAreas = useAppSelector((state) => state.modal.isPreviousPathChooseAreas)
    const myArchivedUnitsOpen = useAppSelector((state) => state.units.myArchivedUnitsOpen)

    const navigateToPrevious = () => {
        if (isPreviousPathChooseAreas) {
            dispatch(setIsPreviousPathChooseAreas(false))
            navigate(-2)
        } else {
            navigate(-1)
        }
    }

    return (
        <>
            <div
                className="header position-fixed bg-white h-auto fw-bold d-flex flex-column shadow-sm px-4 py-2 pt-4"
                style={{
                    top: isShown ? '31px' : 0,
                    left: 0,
                    right: 0,
                    zIndex: 101
                }}
            >
                {userDevice === 'IPhone'
                    ? <div className="h-25px"></div>
                    : <></>
                }
                <div className="w-100 mw-450px mx-auto bg-white">
                    <div className="d-flex flex-column  w-100">
                        <div className="row w-100 g-0">
                            <div className="d-flex align-items-center justify-content-between max-h-30px">
                                <div className="d-flex align-items-center"></div>
                                {active === 'Profile'
                                    ? (
                                        <div
                                            onClick={() => { navigateToPrevious() }}
                                            className="btn btn-sm px-2"
                                        >
                                            <Icon
                                                name={'arrow_circle_left'}
                                                className={'text-muted fs-4x'}
                                            />
                                        </div>
                                    )
                                    : null}

                                {active === 'Dashboard'
                                    ? (
                                        <>
                                            <h2 className={'my-2 flex-grow-1 me-4'}>
                                                {active}
                                            </h2>
                                            <Link
                                                to="/agent/profile/me"
                                                className="text-decoration-none"
                                            >
                                                <div className="btn btn-sm px-2 ">
                                                    <Icon
                                                        name={'account_circle'}
                                                        className={'text-muted fs-4x'}
                                                    />
                                                </div>
                                            </Link>
                                        </>
                                    )
                                    : null}

                                {active === 'Agent catalog'
                                    ? <div onClick={() => { navigate(-1) }}
                                        className="btn btn-sm px-2 ms-2"
                                    >
                                        <Icon
                                            name={'arrow_circle_left'}
                                            className={'text-muted fs-4x'}
                                        />
                                    </div>
                                    : null}
                                {

                                    active === 'Settings' ||
                                        active === 'Performance' ||
                                        active === 'Legal' ||
                                        active === 'Tutorials' ||
                                        active === 'My Subscription' ||
                                        active === 'Orders' ||
                                        active === 'Business'
                                        ? (
                                            <>
                                                <div

                                                    onClick={() => {
                                                        navigate('/agent/menu')
                                                    }}
                                                    className="btn btn-sm px-2"
                                                >
                                                    <Icon
                                                        name={'arrow_circle_left'}
                                                        className={'text-muted fs-4x'}
                                                    />
                                                </div>
                                            </>
                                        )
                                        : null}

                                {
                                    active === 'My units' ||
                                        active === 'My Clients' ||
                                        active === 'My referrals' || active === 'My Units Scheduler'
                                        ? (
                                            <>
                                                <div

                                                    onClick={() => {
                                                        navigate(-1) // '/agent/menu/business'
                                                    }}
                                                    className="btn btn-sm px-2"
                                                >
                                                    <Icon
                                                        name={'arrow_circle_left'}
                                                        className={'text-muted fs-4x'}
                                                    />
                                                </div>
                                            </>
                                        )
                                        : null}

                                {
                                    active === 'My units'
                                        ? <>
                                            <div
                                                onClick={() => {
                                                    dispatch(setMyArchivedUnitsOpen(!myArchivedUnitsOpen))
                                                }}
                                                className="btn btn-sm px-2 ms-2"
                                            >
                                                <Icon
                                                    name={'delete_24dp'}
                                                    className={`${myArchivedUnitsOpen ? 'text-dark' : 'text-muted'} fs-4x`}
                                                    filled={myArchivedUnitsOpen}
                                                />
                                            </div>
                                        </>
                                        : <></>
                                }

                                {
                                    active === 'Purchases' ||
                                        active === 'My Add-Ons'
                                        ? (
                                            <>
                                                <div

                                                    onClick={() => {
                                                        navigate('/agent/menu/orders')
                                                    }}
                                                    className="btn btn-sm px-2"
                                                >
                                                    <Icon
                                                        name={'arrow_circle_left'}
                                                        className={'text-muted fs-4x'}
                                                    />
                                                </div>
                                            </>
                                        )
                                        : null}
                                {
                                    active === 'Agents Leaderboard' ||
                                        active === 'My Performance' ||
                                        active === 'My Membership'
                                        ? (
                                            <>
                                                <div

                                                    onClick={() => {
                                                        navigate('/agent/menu/performance')
                                                    }}
                                                    className="btn btn-sm px-2"
                                                >
                                                    <Icon
                                                        name={'arrow_circle_left'}
                                                        className={'text-muted fs-4x'}
                                                    />
                                                </div>
                                            </>
                                        )
                                        : null}

                                {
                                    active === 'Privacy Policy' ||
                                        active === 'Terms and Conditions'
                                        ? (
                                            <>
                                                <div

                                                    onClick={() => {
                                                        navigate('/agent/menu/legal')
                                                    }}
                                                    className="btn btn-sm px-2"
                                                >
                                                    <Icon
                                                        name={'arrow_circle_left'}
                                                        className={'text-muted fs-4x'}
                                                    />
                                                </div>
                                            </>
                                        )
                                        : null}
                                {active === 'F.A.Q.'
                                    ? <div onClick={() => { navigate('/agent/chats') }}
                                        className="btn btn-sm px-2 ms-2"
                                    >
                                        <Icon
                                            name={'arrow_circle_left'}
                                            className={'text-muted fs-4x'}
                                        />
                                    </div>
                                    : null}
                                {
                                    active === 'Dashboard'
                                        ? null
                                        : <h2 className={`my-2 ${active === 'Schedule' ? '' : 'text-end'}  flex-grow-1 me-4`}>
                                            {active === 'My Units Scheduler' ? <span> Scheduler</span> : active}
                                        </h2>
                                }

                                {active === 'Chat'
                                    ? (
                                        <Link to="/chats" className="text-decoration-none ">

                                            <div className="btn btn-sm px-2 ms-2">
                                                <Icon
                                                    name={'arrow_circle_left'}
                                                    className={'text-muted fs-4x'}
                                                />
                                            </div>
                                        </Link>
                                    )
                                    : null}

                                {active === 'Schedule'
                                    ? (
                                        <>
                                            <div className="d-flex px-2 ">
                                                <div
                                                    className={'d-flex align-items-center  rounded my-1 px-1'}>

                                                    <>

                                                        <div className={'  \'btn btn-sm btn-icon   \'} my-auto ms-2 position-relative  px-1 py-1'} onClick={() => { // 'btn btn-sm btn-icon p-0 py-1 bg-bso'
                                                            dispatch(setShowPopupScheduleFilters(true))
                                                        }}>

                                                            <i className="ki-duotone ki-setting-3 fs-3x my-1">
                                                                <span className="path1 text-dark"></span>
                                                                <span className="path2 text-dark"></span>
                                                                <span className="path3 text-dark"></span>
                                                                <span className="path4 text-dark"></span>
                                                                <span className="path5 text-dark"></span>
                                                            </i>
                                                        </div>

                                                    </>
                                                </div>

                                            </div>
                                        </>
                                    )
                                    : null}
                            </div>
                        </div>
                    </div>
                </div >
            </div >
            <div className="" style={{ height: userDevice === 'IPhone' ? '88px' : '63px' }}></div>

        </>
    )
}
